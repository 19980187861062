import { useEffect } from 'react';
import { useGTMDataLayer } from './useGTMDataLayer';
import { useLocalStorage } from '../useLocalStorage';
import { EventPayload } from '../../../../types/GTM/EventPayload';

export const useSendGTMEventOnceForId = (
  event: EventPayload | undefined,
  deps: any[],
  id: string,
  condition?: boolean,
): void => {
  const [isSentInLocalStorage, setIsSentInLocalStorage] = useLocalStorage(id, false);
  const dataLayer = useGTMDataLayer();
  useEffect(() => {
    if (dataLayer && !isSentInLocalStorage && event) {
      if (typeof condition !== 'undefined') {
        if (condition) {
          dataLayer.push(event);
          setIsSentInLocalStorage(true);
        }
      } else {
        dataLayer.push(event);
        setIsSentInLocalStorage(true);
      }
    }
  }, [event, setIsSentInLocalStorage, isSentInLocalStorage, dataLayer, condition, deps]);
};
